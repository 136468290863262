.vkui > body {
  overflow-x: hidden;
}

.vkui,
.vkui > body,
.vkui__root {
  margin: 0;
  padding: 0;
  height: 100%;
}

.vkui,
.vkui > body,
.vkui__root,
.vkui__portal-root {
  -webkit-font-smoothing: subpixel-antialiased;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-tap-highlight-color: transparent;
  -webkit-text-size-adjust: 100%;
  font-family: var(--font-common);
  color: var(--text_primary);
}

.vkui__root--embedded {
  transform: translate3d(0, 0, 0);
  overflow-x: hidden;
}

.AppRoot {
  height: 100%;
}

.vkui__root--embedded .AppRoot {
  overflow: auto;
}

.vkui--sizeX-regular {
  background: var(--background_page);
}

.AppRoot--no-mouse {
  user-select: none;
}

.vkui__root input,
.vkui__root textarea,
.vkui__root select,
.vkui__root button {
  font-family: inherit;
}

.vkui__root *:focus {
  outline: none;
}
